import React, { useEffect, useState } from "react";
import Layout from "@components/layout";
import Header from "@components/header/header";
import Banner from "@components/banner";
import Video from "@components/video";
import AppFeature from "@components/app-feature";
import CallToAction from "@components/call-to-action";
import PriceHome from "@components/price/price-home";
import TestimonialsHome from "@components/testimonials/testimonials-home";
import AppShot from "@components/app-shot";
import BlogHome from "@components/blog/blog-home";
import ServicesHome from "@components/services/services-home";
import FAQ from "@components/faq";
import Brands from "@components/brands";
import Subscribe from "@components/subscribe";
import Footer from "@components/footer";
import MobileNav from "@components/mobile-nav";
import CardExample from "@components/Publications/PublicationCorousel";
import chatbotIcon from "../assets/images/chatIcon.jpg";
import { AnimatePresence, motion } from "framer-motion";
import ChatBot from "../components/ChatBot";
import ImageGallery from "@components/Articles/ImageGallery";
import ReactGA from "react-ga4";

const MotionBox = motion.div;

const HomeOne = () => {
  const [isOpen, setIsOpen] = useState(false);

  const mainButton = {
    open: { rotate: 90 },
    closed: { rotate: 0 },
  };

  const trackingId = "G-V4WNMH59BK";
  ReactGA.initialize(trackingId);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div>
      <Layout pageTitle="Excellium.">
        <Header />
        <Banner />
        <ServicesHome />
        <CallToAction />
        {/* <Video /> */}
        <AppFeature />
        <CardExample />
        <ImageGallery />
        {/* <PriceHome /> */}
        <TestimonialsHome />
        <AppShot />
        <FAQ />
        <BlogHome />
        <Brands />
        <Subscribe />
        <Footer />
        <MobileNav />
        {/* {isOpen && (
          <div
            style={{
              position: "fixed",
              bottom: "6rem",
              right: "2rem",
              display: "flex",
              flexDirection: "column-reverse",
              zIndex: 99999,
            }}
          >
            <ChatBot />
          </div>
        )} */}
      </Layout>

      {/* <MotionBox
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={mainButton}
        style={{
          position: "fixed",
          bottom: "1.5rem",
          right: "9rem",
          zIndex: 999,
        }}
      >
        <motion.img
          src={chatbotIcon}
          className="blinking"
          alt="Contact Icon"
          style={{
            width: "100px",
            height: "100px",
            marginBottom: "10px",
            borderRadius: "50%",
            cursor: "pointer",
          }}
          onClick={() => setIsOpen(!isOpen)}
        />
      </MotionBox> */}
    </div>
  );
};

export default HomeOne;
